import $ from "jquery";
import "popper.js"; // Bootstrap requires this
import "bootstrap";
import "fslightbox";
import "slick-carousel";
// Can import modules as needed
// ex. import 'bootstrap/js/dist/dropdown';
// import "./scss/style.scss"; // This is now being imported in the webpack.config file 

// This is for slick carousels
global.jQuery = global.$ = require('jquery');


// # ---------------------------------------------------------------
// # Custom Methods...
(function ($) {

	// Header scrolled
	// Check scroll amount, show / hide header items accordingly
	function checkScrolled() {
		let $elem = $("header#site-header");

		if ($elem.length) {
			let $window = $(window);
			let docViewTop = $window.scrollTop();
			let docViewBottom = docViewTop + $window.height();
			let elemTop = $elem.offset().top;
			let elemBottom = elemTop + $elem.height();

			if (elemBottom <= docViewBottom && elemTop >= docViewTop) {
				// $(".lg-hide-on-scrolled").each(function () {
				//   $(this).fadeIn(250);
				// });
				// $(".lg-show-on-scrolled").each(function () {
				//   $(this).fadeOut(1);
				// });
				$("nav.fixed-top").removeClass("navbar-scrolled");
				//$(".custom-logo-link").removeClass("scrolled");
			} else {
				// $(".lg-hide-on-scrolled").each(function () {
				//   $(this).fadeOut(1);
				// });
				// $(".lg-show-on-scrolled").each(function () {
				//   //$(this).fadeIn(250);
				//   $(this).removeClass("d-none");
				//   $(this).fadeIn(1);
				// });
				$("nav.fixed-top").addClass("navbar-scrolled");
				//$(".custom-logo-link").addClass("scrolled");
			}
		}
	}

	// Shrink header on scroll OR page load
	$(window).scroll(function () {
		checkScrolled();
	});

	$(document).ready(function () {
		checkScrolled();
	});



	// Slick sliders
	$(function () {
		$('.logo-slider').slick({
			slidesToShow: 7,
			slidesToScroll: 1,
			infinite: true,
			arrows: true,
			autoplay: true,
			prevArrow:
				'<a class="slick-prev"><i class="bi bi-chevron-left"></i></a>',
			nextArrow:
				'<a class="slick-next"><i class="bi bi-chevron-right"></i></i></a>',

			responsive: [
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 5,
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 3,
					}
				},
			],
		});
	});

	// Add class to header when mobile nav is open
	$('.navbar-toggler').click(function () {
		$('header#site-header').toggleClass('nav-open');
	});


	//----------------------------- Parallax

	// Check if the element is in the viewport.
	// http://www.hnldesign.nl/work/code/check-if-element-is-visible/
	function isInViewport(node) {
		// Am I visible? Height and Width are not explicitly necessary in visibility
		// detection, the bottom, right, top and left are the essential checks. If an
		// image is 0x0, it is technically not visible, so it should not be marked as
		// such. That is why either width or height have to be > 0.
		var rect = node.getBoundingClientRect();
		return (
			(rect.height > 0 || rect.width > 0) &&
			rect.bottom >= 0 &&
			rect.right >= 0 &&
			rect.top <=
			(window.innerHeight || document.documentElement.clientHeight) &&
			rect.left <= (window.innerWidth || document.documentElement.clientWidth)
		);
	}

	// Attach scroll event to window. Calculate the scroll ratio of each element
	// and change the image position with that ratio.
	$(window).scroll(function () {
		var scrolled = $(window).scrollTop();
		$(".home .page-content").each(function (index, element) {
			var initY = $(this).offset().top;
			var height = $(this).height();
			var endY = initY + $(this).height();

			// Check if the element is in the viewport.
			var visible = isInViewport(this);
			if (visible) {
				var diff = scrolled - initY;

				// var ratio = Math.round((diff / height) * 100);
				// FIX: Set ratio to fix BG jumping away on tablet
				var ratio = Math.round(scrolled / 2);

				$(this).css(
					"background-position",
					"center bottom " + parseInt(-(ratio * .2)) + "px"
					//'center 50% bottom ' + parseInt(-(ratio * 1.5)) + "px"
				);
			}
		});
	});

	//----------------------------- END Parallax


	// Add open button after mobile menu items with children
	$('#menu-primary-menu li.menu-item-has-children > a').after('<div class="mobile-sub-menu-toggle"><i class="fa-solid fa-plus"></i><i class="fa-solid fa-minus"></i></div>');
	//$('.mobile-menu-lower #header-secondary-navigation li.menu-item-has-children > a').after($('<div class="mobile-sub-menu-toggle"><i class="fa fa-plus"></i><i class="fa fa-minus"></i></div>'));

	// Mobile Sub Menu Toggle
	$('.mobile-sub-menu-toggle').click(function (e) {
		e.preventDefault();
		// $('.mobile-sub-menu-toggle').not($(this)).each(function () {
		// 	$(this).removeClass('open');
		// 	return $(this).next('ul.dropdown-menu').slideUp('fast');
		// });
		$(this).next('ul.dropdown-menu').slideToggle('fast');
		return $(this).toggleClass('open');
	});

	// Toggle for custom upcoming tours dropdown menu
	$('h2.upcoming-label').click(function (e) {
		e.preventDefault();
		$('ul.dropdown-menu.upcoming-tours').slideToggle('fast');
		return $('h2.upcoming-label .mobile-sub-menu-toggle--upcoming').toggleClass('open');
	});

	//   // Toggle borough filter dropdown visibility 
	//   $('.borough-filters > li.cat-item').hover(function(){
	// 	$(this).children('ul.children').toggleClass('showing');
	//   })


})($);